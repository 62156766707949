/* GalleryPage.css */
.page-container {
  padding: 20px;
}

.modal-dialog {
  max-width: 250%;
  margin: auto;
}

.modal-body img {
  width: 100%;
  height: auto;
}
